// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Button, Stack } from "@mui/material";
import LogoSection from "layout/MainLayout/LogoSection";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { isAdmin } from 'utils/common';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const account = useSelector((state) => state.account);
  const userIsAdmin = isAdmin();

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box component="span" sx={{ flexGrow: 1 }}>
          <LogoSection />
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      {window.innerWidth <= 600 ? (
        <>
        <Stack spacing={1} direction="row" justifyContent="center">
            <Button
              component={Link}
              variant="contained"
              to="/"
              color="inherit"
            >
              首页
            </Button>
            <Button
              component={Link}
              variant="contained"
              to="/panel/chat"
              color="inherit"
            >
              聊天
            </Button>
            <Button
              component={Link}
              variant="contained"
              to="/panel/dashboard"
              color="inherit"
            >
              总览
            </Button>
            {account.user ? (
              <Button
                component={Link}
                variant="contained"
                to="/about"
                color="inherit"
              >
                关于
              </Button>
            ) : (
              <Button
                component={Link}
                variant="contained"
                to="/login"
                color="primary"
              >
                登录
              </Button>
            )}
        </Stack>
        </>
      ) : (
        <>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 1 }} />
          <Stack spacing={1} direction="row" justifyContent="center">
            <Button
              component={Link}
              variant="contained"
              to="/"
              color="inherit"
            >
              首页
            </Button>
            <Button
              component={Link}
              variant="contained"
              to="/panel/chat"
              color="inherit"
            >
              聊天
            </Button>
            <Button
              component={Link}
              variant="contained"
              to="/panel/dashboard"
              color="inherit"
            >
              总览
            </Button>
            {account.user && userIsAdmin ? (
              <Button
                component={Link}
                variant="contained"
                to="/panel/channel"
                color="inherit"
              >
                渠道
              </Button>
            ) : (
              <></>
            )}
            <Button
              component={Link}
              variant="contained"
              to="/panel/token"
              color="inherit"
            >
              令牌
            </Button>
            {account.user && userIsAdmin ? (
              <Button
                component={Link}
                variant="contained"
                to="/panel/redemption"
                color="inherit"
              >
                兑换
              </Button>
            ) : (
              <></>
            )}
            <Button
              component={Link}
              variant="contained"
              to="/panel/topup"
              color="inherit"
            >
              充值
            </Button>
            {account.user && userIsAdmin ? (
              <Button
                component={Link}
                variant="contained"
                to="/panel/user"
                color="inherit"
              >
                用户
              </Button>
            ) : (
              <></>
            )}
            <Button
              component={Link}
              variant="contained"
              to="/panel/log"
              color="inherit"
            >
              日志
            </Button>
            <Button
              component={Link}
              variant="contained"
              to="/panel/profile"
              color="inherit"
            >
              设置
            </Button>
            <Button
              component={Link}
              variant="contained"
              to="/about"
              color="inherit"
            >
              关于
            </Button>
            {account.user ? (
              <Button
                component={Link}
                variant="contained"
                to="/panel"
                color="primary"
              >
                控制台
              </Button>
            ) : (
              <Button
                component={Link}
                variant="contained"
                to="/login"
                color="primary"
              >
                登录
              </Button>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default Header;
