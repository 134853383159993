// material-ui
// import logo from 'assets/images/logo.svg';
import { useSelector } from 'react-redux';
// material-ui
import { Grid, Typography } from '@mui/material';

import { useNavigate,useLocation } from 'react-router-dom';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const siteInfo = useSelector((state) => state.siteInfo);
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <img src={siteInfo.logo} alt={siteInfo.system_name} height="50" />
      {window.innerWidth <= 600 ? (
        <>
        { (currentPath === "/" || currentPath === "/about" || currentPath === "/login" || currentPath === "/register" || currentPath === "/reset" || currentPath === "/panel/chat") ? (
          <Typography variant="h3">&nbsp;&nbsp;</Typography>
          ) : (
            <Typography variant="h3">&nbsp;&nbsp;{siteInfo.system_name}</Typography>
        )}
        </>
        ) : (
        <Typography variant="h3">&nbsp;&nbsp;{siteInfo.system_name}</Typography>
      )}
    </>
  )
  // return <img src={siteInfo.logo || logo} alt={siteInfo.system_name} height="50" />;
};

export default Logo;
